<template>
  <div class="applyshop">
    <!-- 主体 -->
    <div class="mainbody">
      <div class="mainbody-title">
        <span>申请开店</span> - <span>{{ reg }}</span> -
        <span>{{ prefixName }}</span>
      </div>
      <Steps :steps="1" />

      <div class="personal-info">
        <div class="info-name">姓名:&nbsp;&nbsp;{{ legalName }}</div>
        <div class="info">
          <div class="info-left">
            <div class="IDnum pd37">
              <p>身份证号:</p>
              <a-input class="w350" v-model="idCard" :maxLength="18" />
            </div>
            <div class="politics pd37">
              <p>政治面貌:</p>
              <a-select
                :value="politicalStatus"
                style="width: 220px"
                @select="getval"
              >
                <a-select-option v-for="item in politics" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
            <div class="address pd37">
              <p>住所</p>
              <a-textarea
                class="w350"
                v-model="stayAddr"
                :auto-size="{ minRows: 3, maxRows: 3 }"
              />
            </div>
            <div class="Email pd37">
              <p>电子邮箱:</p>
              <a-input class="w350" v-model="email" />
            </div>
            <div class="postal-code pd37">
              <p>文化程度</p>
              <a-select
                :value="eduLevel"
                style="width: 220px"
                @select="(e) => (eduLevel = e)"
              >
                <a-select-option v-for="item in education" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
            <div class="small-scale pd37">
              <p class="small-scal-title">小规模纳税人登记名称</p>
              <a-row type="flex" align="middle" class="tp10">
                <a-col :span="4">{{prefixName}}</a-col>
                <a-col :span="10" style="padding-left:1px">
                <a-input class="pl" style="width: 100%" v-model="firstAltName" @change="firstAltName?dy=false:dy=true"></a-input>
                </a-col>
                <a-col :span="9" align="center">{{ suffixName }}</a-col>
              </a-row>
              <p>
                备选1：&nbsp;&nbsp;<a-input
                  class="pl"
                  v-model="secondAltName"
                />
              </p>

              <p>
                备选2：&nbsp;&nbsp;<a-input class="pl" v-model="thirdAltName" />
              </p>
            </div>
          </div>
          <div class="info-right">
            <div class="Phone pd37">
              <p>移动电话</p>
              <a-input
                v-model="bankReservedMobile"
                :maxLength="11"
               @input="bankReservedMobile=$event.target.value.replace(/[^\d]/g,'')"
                class="w350"
              />
            </div>
            <div class="nation pd37">
              <p>民族</p>
              <a-input v-model="belongNation" style="width: 130px" />
            </div>
            <div class="postal-code pd37">
              <p>身份证有效期</p>
              <a-space>
                <!-- <a-range-picker format="YYYY-MM-DD" :value="[idCardStartDate, idCardEndDate]" @change="onIndateChange" class="w350" /> -->
                <a-date-picker v-model="idCardStartDate" placeholder="开始日期" :disabled-date="disabledStartDate" style="width: 135px">
                </a-date-picker>
                至
                <a-date-picker v-if="!long" v-model="idCardEndDate" placeholder="结束日期" :disabled-date="disabledEndDate" style="width: 135px">
                </a-date-picker>
                <a-checkbox v-model="long" @change="idCardEndDate = undefined">长期
                </a-checkbox>
              </a-space>
            </div>
            <div class="postal-code pd37 pd40">
              <p>邮政编码</p>
              <a-input v-model="postalCode" class="w350" />
            </div>
            <div class="annotation">
              <p>
               <span class="red" v-if="dy">{{error.firstAltName}}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="signtory">
          <span>授权他人为该交易主体的合同签署人</span>
          &nbsp;&nbsp;
          <a-switch @change="swt">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
          <a-row
            type="flex"
            align="middle"
            class="ant-row"
            :class="sw != true ? 'opct' : ''"
          >
            <a-col :span="2">签署人姓名:</a-col>
            <a-col :span="7"
              ><a-input
                v-model="signerName"
                placeholder="姓名"
                class="notact-input"
                :class="signerName != '' ? 'activate-input' : ''"
            /></a-col>
            <a-col :span="2"></a-col>
            <a-col :span="2" align="center">身份证号: </a-col>
            <a-col :span="7"
              ><a-input
                v-model="signerIdCard"
                placeholder="身份证号"
                class="notact-input"
                :maxLength="18"
                :class="signerIdCard != null ? 'activate-input' : ''"
            /></a-col>
            <a-col :span="5"></a-col>
          </a-row>
          <a-row
            type="flex"
            align="middle"
            class="ant-row"
            :class="sw != true ? 'opct' : ''"
          >
            <a-col :span="2">签署手机号：</a-col>
            <a-col :span="7"
              ><a-input
                v-model="signerMobile"
                placeholder="手机号"
                :maxLength="11"
               @input="signerMobile=$event.target.value.replace(/[^\d]/g,'')"
                class="notact-input"
                :class="signerMobile != null ? 'activate-input' : ''"
            /></a-col>
          </a-row>
        </div>
      </div>
      <div class="next">
        <a-space :size="46">
          <a-button type="primary" @click="$router.push(`/account-center/apply?id=${$route.query.individualId}`)">
            查看个人信息
          </a-button>
          <a-button
            style="width: 100px"
            type="primary"
            @click="next"
            :loading="loading"
            ><span v-if="!loading">提交</span>
          </a-button>
        </a-space>
        
      </div>
    </div>
  </div>
</template>
<script>
import { addapply, selectregion } from '../../api/seller'
import { echo } from '../../api/user'
import Steps from './component/steps.vue'
import { politics, education } from '@/utils/constArr'
import moment from 'moment'
var myreg =/^1[3-9][0-9]{9}$/
const regEmail = /.{1,}@.{1,}\..{1,}/
export default {
  components: {
    Steps
  },
  data() {
    return {
      politics,
      education,
      steps: 2,
      reg: '个体工商户注册申请',

      sw: false, //签署人旋钮
      legalName: '', // 法人姓名
      idCard: '', // 法人身份证号
      sex: null, // 性别
      belongNation: '', // 民族
      stayAddr: '', // 住所地址
      // supplierName: "", // 供应商名称
      bankReservedMobile: '', // 银行预留手机号
      politicalStatus: '群众', // 政治面貌
      eduLevel: '高中', //文化程度
      email: '', // 电子邮件
      postalCode: '', // 邮编
      prefixName: '', // 公司注册名前缀
      suffixName: '', // 公司注册名后缀
      firstAltName: '', // 第一工商名称
      secondAltName: '', // 第二工商名称
      thirdAltName: '', // 第三工商名称
      isAuthSigner: '0', // 是否授权人（0：不授权，1:授权）
      signerName: '', // 签署人姓名
      signerIdCard: null, // 签署人身份证号
      signerMobile: null, // 签署人手机号
      regionId: '', //祱区id
      taxRegionName: '', //祱区名称
      commercialName: '', //关联公司名字
      relatedPurchaserId: '', //关联采购商ID
      idCardEndDate: undefined, // 身份证结束时间
      idCardStartDate: undefined, // 开始时间
      error: {
        email: '请填写正确的电子邮箱',
        firstAltName: '请填写第一工商名称',
        signerName: '请填输入签署人姓名',
        signerIdCard: '请填输入签署人身份证号',
        signerMobile: '请填输入正确的签署人手机号'
      },
      loading: false,
      dy:false,
      long: false // 身份证长期
    }
  },
  created() {
    this.getID()
    this.getface()
  },
  mounted() {},
  methods: {
    getface() {
      // face({ bizNo: this.$route.query.bizNo }).then((res) => {
      //   if (res.code == 200) {
      //     this.idCard = res.data.id
      //     this.legalName = res.data.name
      //     this.sex = res.data.gender === '男' ? 1 : 0
      //     this.belongNation = res.data.race
      //     this.stayAddr = res.data.address
      //     this.firstAltName=res.data.name
      //   } else {
      //     this.$message.error(res.message)
      //   }
      // })
      echo({individualId: this.$route.query.individualId}).then(res => {
        if (res.code == 200) {
          this.idCard = res.data.idCard
          this.legalName = res.data.legalName
          // this.sex = res.data.gender === '男' ? 1 : 0
          this.belongNation = res.data.belongNation
          this.stayAddr = res.data.stayAddr
          this.firstAltName=res.data.legalName
          this.idCardStartDate = res.data.idCardStartDate
          if (res.data.idCardEndDate === '长期') {
            this.long = true
          } else {
            this.idCardEndDate = res.data.idCardEndDate
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getID() {
      this.bankReservedMobile = this.$route.query.bankReservedMobile
      this.regionId = this.$route.query.regionId
      this.taxRegionName = this.$route.query.taxRegionName
      this.commercialName = this.$route.query.commercialName
      this.relatedPurchaserId = this.$route.query.relatedPurchaserId
      selectregion({ regionName: this.$route.query.taxRegionName.trim()}).then(
        (res) => {
          this.prefixName = JSON.parse(res.data[0].config).registrationPrefix
          this.suffixName = JSON.parse(res.data[0].config).registrationSuffix
        }
      )
    },
    swt(e) {
      this.sw = e
      // this.isAuthSigner= (e==true?'1':'0')
      if (e == true) {
        this.isAuthSigner = '1'
      } else {
        this.isAuthSigner = '0'
      }
    },
    next() {  
      let verify = false
      if (this.idCardStartDate && !this.long && !this.idCardEndDate) {
        this.$message.error('请填写正确的身份证有效期')
      } else if (this.email && !regEmail.test(this.email)) {
        this.$message.error(this.error.email)
      } else if (!this.firstAltName) {
        this.dy=true
        this.$message.error(this.error.firstAltName)
      } else if (this.isAuthSigner == '1' && !this.signerName) {
        this.$message.error(this.error.signerName)
      } else if (this.isAuthSigner == '1' && !this.signerIdCard) {
        this.$message.error(this.error.signerIdCard)
      } else if (this.isAuthSigner == '1' && !myreg.test(this.signerMobile)) {
        this.$message.error(this.error.signerMobile)
      } else {
        verify = true
        this.loading = true
      }
      if (verify) {
        const config = {
          legalName: this.legalName,
          idCard: this.idCard,
          sex: this.sex,
          belongNation: this.belongNation,
          supplierName: this.prefixName + this.firstAltName + this.suffixName,
          bankReservedMobile: this.bankReservedMobile,
          politicalStatus: this.politicalStatus,
          eduLevel: this.eduLevel,
          email: this.email,
          regionId: this.regionId,
          taxRegionName: this.taxRegionName,
          postalCode: this.postalCode,
          prefixName: this.prefixName,
          suffixName: this.suffixName,
          firstAltName: this.firstAltName,
          secondAltName: this.secondAltName ,
          thirdAltName: this.thirdAltName ,
          isAuthSigner: this.isAuthSigner,
          signerName: this.signerName,
          signerIdCard: this.signerIdCard,
          signerMobile: this.signerMobile,
          stayAddr: this.stayAddr,
          purchaserName: this.commercialName,
          purchaserId: this.relatedPurchaserId,
          idCardStartDate: this.idCardStartDate ? moment(this.idCardStartDate).format('YYYY-MM-DD') : undefined,
          idCardEndDate: this.long && this.idCardStartDate ? '长期' : (this.idCardEndDate ? moment(this.idCardEndDate).format('YYYY-MM-DD') : undefined)
        }
        addapply(config).then((res) => {
          this.loading = false
          if (res.code == 200) {
            this.$store.dispatch("getuserMenu")
            this.$router.replace({
              path: '/accomplish',
              query: {
                id: res.data, 
              }
            })
          } else {
            this.$message.error(res.message)
          }
        }).catch(r=>{
            this.loading = false
          this.$message.error(r.message)
        })
      }
    },
    getval(e) {
      this.politicalStatus = e
    },
    onIndateChange (m, e) {
      console.log(e);
      this.idCardStartDate = e[0]
      this.idCardEndDate = e[1]
    },
    disabledStartDate (current) {
      return current && this.idCardEndDate && current > moment(this.idCardEndDate)
    },
    disabledEndDate (current) {
        return current && this.idCardStartDate && current < moment(this.idCardStartDate)
    },
  }
}
</script>
<style lang="less" scoped>
.applyshop {
  margin-bottom: 100px;
   max-width: 1440px;
   min-width: 990px; 
   @media screen and (max-width: 1440px){
   padding: 0 15px;
   }
    margin: 20px auto;
  .mainbody {
    height: 1064px;
    background-color: #ffffff;
    position: relative;
    .mainbody-title {
      :nth-child(1) {
        font-size: 20px;
      }
      padding: 30px 0 0 50px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-size: 16px;
      font-weight: 700;
    }
    .personal-info {
      .info-name {
        padding: 50px 0 0 120px;
        font-size: 20px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.847058823529412);
      }
      .info {
        display: flex;
        padding: 0 0 0 120px;
        font-size: 14px;
        font-weight: 400;
        .info-left {
          .IDnum {
            padding: 20px 0 35px 0;
          }
          .small-scale {
            .pl {
              width: 145px;
              padding: 0 0 0 10px !important;
            }
            p {
              padding: 10px 0 0 0;
            }
            :nth-child(1) {
              padding: 0;
            }
            .small-scal-title {
              font-size: 14px;
              font-weight: 700;
              color: rgba(0, 0, 0, 0.847058823529412);
            }
          }
        }
        .info-right {
          padding: 0 0 0 85px;
          .Phone {
            padding: 20px 0 35px 0;
          }
          .annotation {
            padding: 0 0 0 0px;
            position: relative;
            p {
              left: -90px;
              position: absolute;
              padding: 46px 0 0 0;
              line-height: 20px;
              width: 375px;
            }
          }
        }
        .pd37 {
          padding: 0 0 30px 0;
          line-height: 30px;
        }
        .pd40 {
          padding-top: 40px;
        }
      }
      .signtory {
        padding: 0 0 0 120px;
        font-size: 15px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.847058823529412);
        .ant-row {
          font-size: 14px;
          font-weight: 400;
          padding: 15px 0;
          .notact-input {
            background-color: rgba(245, 245, 245, 1);
          }
          .activate-input {
            background-color: #ffffff;
          }
        }
        .opct {
          opacity: 0;
        }
      }
    }
    .tp10 {
      padding: 10px 0 0 0;
    }
    .w54 {
      display: inline-block;
    }
    .red{
      color: red;
    }
    .next {
      text-align: center;
    }
  }
  .w350{
    width: 350px;
    @media screen and (max-width: 1440px){
    width: 270px;
   }
  }
  .w80 {
    width: 112px;
  }
}
</style>